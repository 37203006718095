import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserPreferencesService} from '../user-preferences.service';
import {TileJsonService} from './tile-json.service';
import {Style} from 'mapbox-gl';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {MapStyle, mapStyles} from './map-styles';
import {compress, decompress} from '../../utils/messagepack-url';
import {UserPreferences} from 'api/models/user-preferences';
import {AzureMapsService} from '../azure-maps.service';
import {defaultLayerOrder, defaultMapConfigLayers, MapConfig, MapConfigLayers} from '../../model/map-config';

@Injectable({
    providedIn: 'root'
})
export class MapConfigService {
    private mapConfigSubject = new BehaviorSubject<MapConfig>(null);
    private ready$ = new BehaviorSubject(false);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userPreferencesService: UserPreferencesService,
        private tileJsonService: TileJsonService,
        private azureMapsService: AzureMapsService
    ) {
        this.userPreferencesService.asObservable().subscribe(preferences => this.onPreferencesChange(preferences));
    }

    asObservable(): Observable<MapConfig> {
        return this.mapConfigSubject.asObservable().pipe(filter(it => it !== null));
    }

    async selectMapStyle(mapStyle: MapStyle) {
        const style = await this.fetchStyle(mapStyle.style);

        this.update({
            style,
            styleName: mapStyle.style
        });
    }

    async updateLayer<T extends keyof MapConfigLayers>(layer: T, config: Partial<MapConfigLayers[T]>) {
        await this.ready$.pipe(
            filter(it => it),
            take(1)
        ).toPromise();

        return this.update({
            layerOrder: this.mapConfigSubject.value.layerOrder,
            layers: {
                ...this.mapConfigSubject.value.layers,
                [layer]: {
                    ...this.mapConfigSubject.value.layers[layer],
                    ...config
                }
            }
        });
    }

    async update(partialConfig: Partial<MapConfig>) {
        const newMapConfig = {
            ...this.mapConfigSubject.value,
            ...partialConfig,
        };
        this.mapConfigSubject.next(newMapConfig);
        this.updateRouteQueryParams(newMapConfig);
    }

    saveToPreferences() {
        const mapConfig = this.mapConfigSubject.value;

        const userPreferences: UserPreferences = {
            backgroundLayer: mapConfig.styleName,
            layers: Object.entries(mapConfig.layers).map(([name, value]) => ({name, ...value})),
            tourSeen: true,
            poiCategories: this.azureMapsService.getActivePOICategories(),
            layerOrder: mapConfig.layerOrder
        };

        return this.userPreferencesService.save(userPreferences).toPromise();
    }

    getActiveLayers(): Partial<MapConfigLayers> {
        const mapConfigLayers = this.mapConfigSubject.value.layers;
        return Object.fromEntries(Object.entries(mapConfigLayers).filter(it => it[1].active));
    }

    private async onPreferencesChange(preferences: UserPreferences) {
        const queryParams = this.route.snapshot.queryParams;

        const styleName = mapStyles.find(it => it.style === queryParams.style)?.style
            || (mapStyles.find(it => it.style === preferences.backgroundLayer) || mapStyles[0]).style;

        const style = await this.fetchStyle(styleName);
        const layers = defaultMapConfigLayers;
        const layerOrder = (preferences.layerOrder === undefined ||
                            preferences.layerOrder.length === 0) ? defaultLayerOrder: preferences.layerOrder;
        preferences.layers.forEach(layer => {
            layers[layer.name] = layer as any;
        });
        if (queryParams.layers) {
            Object.assign(layers, decompress(queryParams.layers));
        }

        const mapConfig: MapConfig = {
            style, styleName, layers, layerOrder,
            filters: this.mapConfigSubject.value?.filters || []
        };

        this.mapConfigSubject.next(mapConfig);
        this.updateRouteQueryParams(mapConfig);
        if (!this.ready$.value) {
            this.ready$.next(true);
        }
    }

    private async fetchStyle(styleName: string): Promise<Style> {
        const mapStyle = mapStyles.find(it => it.style === styleName);
        if(!mapStyle) {
            console.error('Unknown style', styleName);
            return await this.tileJsonService.fetchArcgisTileJson('arcgis/streets');
        }
        return await this.tileJsonService.fetchArcgisTileJson(mapStyle.tileJsonName);
    }

    private async updateRouteQueryParams(mapConfig: MapConfig) {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                ...this.route.snapshot.queryParams,
                style: mapConfig.styleName,
                layers: compress(mapConfig.layers)
            },
            replaceUrl: true
        });
    }
}
